import React, {Component} from 'react';
import Footer from '../../component/Footer';
import Navbar from '../../component/NavbarComp';
import ProductRRContainer from '../../container/ProductRRContainer';
import ProductRRDesc from '../../container/ProductRRDesc';
import ProductRRDesc2 from '../../container/ProductRRDesc2';
import ProductRRDesc3 from '../../container/ProductRRDesc3';

class ProductRR extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <Navbar/>
                <ProductRRContainer/>
                <ProductRRDesc/>
                <ProductRRDesc2/>
                <ProductRRDesc3/>
                <Footer/>
            </div>
        );
    }
}
 
export default ProductRR;