import React, {Component} from 'react';
import Footer from '../../component/Footer';
import Navbar from '../../component/NavbarComp';
import MobileILPContainer from '../../container/MobileILPContainer';
import MLosCard from '../../container/MLosCard';

class Milp extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <Navbar/>
                <MobileILPContainer/>
                <MLosCard/>
                <Footer/>
            </div>
        );
    }
}
 
export default Milp;