import React, {Component} from 'react';
import Footer from '../../component/Footer';
import Navbar from '../../component/NavbarComp';
import ProductFinanceContainer from '../../container/ProductFinanceContainer';
import ProductFinanceDesc from '../../container/ProductFinanceDesc';
import ProductFinanceDesc2 from '../../container/ProductFinanceDesc2';
import ProductFinanceDesc3 from '../../container/ProductFinanceDesc3';
import ProductFinanceDesc4 from '../../container/ProductFinanceDesc4';

class ProductFinance extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <Navbar/>
                <ProductFinanceContainer/>
                <ProductFinanceDesc/>
                <ProductFinanceDesc2/>
                <ProductFinanceDesc3/>
                <ProductFinanceDesc4/>
                <Footer/>
            </div>
        );
    }
}
 
export default ProductFinance;